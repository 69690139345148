<template>
  <v-card flat class="rounded-t-0">
    <div class="emoji">🎉</div>
    <v-card-text class="text-center">
      Herzlichen Glückwunsch zum Geburtstag!</v-card-text
    >
    <v-divider />
    <v-card-actions>
      <v-btn
        v-if="!confetti"
        @click="start"
        color="primary"
        text
        small
        block
        target="_blank"
        >lass uns feiern!</v-btn
      >
      <v-btn
        v-else
        @click="stop"
        color="primary"
        text
        small
        block
        target="_blank"
        >genug! lass uns weiterarbeiten!</v-btn
      >
      <audio ref="audio" src="@/assets/happy-birthday.mp3" />
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "BirthdayModule",
  data: () => {
    return {
      confetti: false,
      timeout: null,
    };
  },
  created() {
    if (this.$_profilePerson.birthday) {
      this.$emit("loaded");
    }
  },
  methods: {
    start() {
      this.confetti = true;
      this.$refs.audio.play();
      this.timeout = window.setTimeout(() => this.stop(), 18000);

      this.$confetti.start({
        defaultColors: ["#3348B5", "#41A7D1", "#E8CC00", "#FCA400", "#74BF3D"],
      });
    },
    stop() {
      this.confetti = false;
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
      window.clearTimeout(this.timeout);
      this.$confetti.stop();
    },
  },
};
</script>
<style scoped>
div.emoji {
  font-size: 10rem;
  text-align: center;
  text-align: middle;
}
</style>
